import React, { PureComponent, createRef } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Navbar, Nav, Row } from 'react-bootstrap';
import { Link } from "gatsby"
import BrandImage from '../images/brand.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import faFacebook from '@fortawesome/fontawesome-free-brands/faFacebookSquare';
import faYoutube from '@fortawesome/fontawesome-free-brands/faYoutube';
import faLinkedIn from '@fortawesome/fontawesome-free-brands/faLinkedin';
import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram';
import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter';
import WellnessUniverseIcon from './wellness-universe-icon';

import "../styles/app.scss";
import { Fragment } from 'react';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

export default class Layout extends PureComponent {
  constructor(props) {
    super(props);
    this.scrollTicking = false;
    this.lastScrollPosition = null;
    this.bodyWidth = null;
    this.stretchRowOffset = null;
    this.resizeObs = null;
    this.navbar = createRef();
    this.container = createRef();
    this.social = createRef();
    this.footer = createRef();
    this.state = {
      scrolledToTop: true
    };
  }

  manageNavResize = scrollPosition => {
    if (scrollPosition === 0 && !this.state.scrolledToTop) {
      this.setState({ scrolledToTop: true });
    } else if (this.state.scrolledToTop && scrollPosition !== 0) {
      this.setState({ scrolledToTop: false });
    }
  }

  scrollEventListener = e => {
    this.lastScrollPosition = window.scrollY;
    if (!this.scrollTicking) {
      window.requestAnimationFrame(() => {
        this.manageNavResize(this.lastScrollPosition);
        this.scrollTicking = false;
      });
      this.scrollTicking = true;
    }
  };

  getStretchRowStyles = () => {
    if (this.container.current == null) {
      return {
        width: '100%'
      };
    }
    const style = this.getCurrentStyleFromRef(this.container);
    return {
      width: this.bodyWidth || '100%',
      right: style['margin-left'] || undefined
    };
  };

  getDefaultContentStyles = () => {
    if (this.navbar.current == null || this.social.current == null || this.footer.current == null) {
      return {
      };
    }
    const navStyle = this.getCurrentStyleFromRef(this.navbar);
    const socialStyle = this.getCurrentStyleFromRef(this.social);
    const footerStyle = this.getCurrentStyleFromRef(this.footer);
    return {
      minHeight: `calc(100vh - ${navStyle.height} - ${socialStyle.height} - ${footerStyle.height})`
    };
  };

  getCurrentStyleFromRef = ref => {
    if (ref.current == null) {
      return null;
    }
    return ref.current.currentStyle || window.getComputedStyle(ref.current);
  };

  componentDidMount() {
    document.addEventListener("scroll", this.scrollEventListener);
    this.resizeObs = new ResizeObserver((entries, observer) => {
      for (let entry of entries) {
        switch(entry.target) {
          case document.body: {
            this.bodyWidth = entry.target.clientWidth;
            break;
          }
          default: {
            continue;
          }
        }
      }
      this.forceUpdate();
    });
    this.resizeObs.observe(document.body);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.scrollEventListener);
    this.resizeObs.disconnect();
  }

  render() {
    const stretchRowStyles = this.getStretchRowStyles();
    const defaultContentStyles = this.getDefaultContentStyles();
    const childrenWithProps = React.Children.map(this.props.children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { stretchRowStyles, defaultContentStyles });
      }
      return child;
    });
    const { scrolledToTop } = this.state;
    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bold & Balanced Living, with Toni Lynn, PhD</title>
          <meta name="description" content="The website for Bold & Balanced Living, with Toni Lynn, PhD. Dr. Toni is a life coach and educator helping leaders and professionals manage their well-being in order to achieve peak performance and prevent burn-out."></meta>
        </Helmet>
        <div>
          <Navbar ref={this.navbar} className={!scrolledToTop ? 'nav-scrolled' : undefined} bg="light" expand="lg" sticky='top'>
            <Link className='brand-link' to="/">
              <Navbar.Brand>
                <img className={'brand-image'} src={BrandImage} alt='Bold & Balanced Living, with Toni Lynn, PhD' />
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Link className='nav-link' to='/'>
                  Home
                </Link>
                <Link className='nav-link' to='/learn-more'>
                  Learn More
                </Link>
                <Link className='nav-link' to='/mentorship'>
                  Private Mentorship
                </Link>
                <a className='nav-link' href='https://courses.boldandbalancedcoaching.com'>
                  Courses
                </a>
                <Link className='nav-link' to='/resources'>
                  Resources
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Container ref={this.container}>
            {childrenWithProps}
            <Row ref={this.social} className='social-row' style={stretchRowStyles}>
              <div className='social-row-content' >
                <a href='https://www.youtube.com/channel/UCrX867jkRvIonGzYil371BQ' target='_blank' rel="noreferrer">
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a href='https://www.facebook.com/coachingwithtoni' target='_blank' rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href='https://www.linkedin.com/in/drtoniwarner/' target='_blank' rel="noreferrer">
                  <FontAwesomeIcon icon={faLinkedIn} />
                </a>
                <a href='https://www.instagram.com/BoldnBalanced_coachingwithToni/' target='_blank' rel="noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href='https://twitter.com/authentic_drmom' target='_blank' rel="noreferrer">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href='https://www.facebook.com/groups/lifesresetbutton' target='_blank' rel="noreferrer">
                  <FontAwesomeIcon icon={faUsers} />
                </a>
                <a href='https://www.thewellnessuniverse.com/world-changers/toniwarner/' target='_blank' rel="noreferrer">
                  <WellnessUniverseIcon className='wellness-icon' />
                </a>
              </div>
            </Row>
            <Row ref={this.footer} className='footer-row' style={stretchRowStyles}>
              <div className='footer-row-content'>
                <ul className='footer-links'>
                  <li>
                    <Link className='footer-link' to='/disclaimer'>
                      Disclaimer
                    </Link>
                  </li>
                  <li>
                    <Link className='footer-link' to='/terms-of-service'>
                      Terms of Service 
                    </Link>
                  </li>
                  <li>
                    <Link className='footer-link' to='/privacy-policy'>
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
                <div>
                  Copyright © {new Date().getFullYear()} Toni Lynn PhD, Enterprises
                </div>
                <div>Icons made by <a href="https://www.flaticon.com/authors/prettycons" title="prettycons">prettycons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
              </div>
            </Row>
          </Container>
        </div>
      </Fragment>
    )
  }
}